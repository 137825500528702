@import "/src/styles/colors.scss";

.stepper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  height: 100%;
  max-width: 100%;

  *::-webkit-scrollbar {
    background-color: transparent;
    width: 12px;
    height: 12px;
    z-index: 999999;
  }

  *::-webkit-scrollbar-track {
    background-color: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 12px;
    border: 0 solid #4a443a;
  }

  *::-webkit-scrollbar-button {
    display: none;
  }

  *:hover::-webkit-scrollbar-thumb {
    background-color: #a0a0a5;
    border: 2px solid #4a443a;
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
    border: 2px solid #a0a0a5;
  }
}

.stepperNav {
  display: flex;
  flex-flow: column nowrap;
  padding: 0px 20px;
  max-width: 30%;
}

.stepperContent {
  max-width: 70%;
  height: 100%;
  min-height: 70%;
  max-height: 600px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.stepperComponents {
  width: 100%;
  height: calc(100% - 45px);
  max-height: calc(100% - 45px);
  overflow: hidden;
  background-color: #4a443a;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: rgba(255, 255, 255, 0.05) solid 1px;
}

.stepperComponentNav {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  max-height: 100%;
  height: 100%;
  transition: left 0.3s ease;
}

.stepComponentOverflow {
  max-height: 100%;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  max-width: 100%;
  overflow: auto;
}

.stepComponent {
  min-width: 100%;
  max-width: 100%;
  height: inherit;
  padding: 4% 5%;
}

.stepperNavigation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  height: 4%;
}

.step {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-right: 30px;
  padding-top: 16px;

  &[incoming-step="true"] {
    opacity: 0.3;
    transform: scale(0.8);
    transform-origin: 6%;
  }

  &[past-step="true"] {
    opacity: 0.7;
    transform: scale(0.8);
    transform-origin: 6%;
  }
}

.stepTitle {
  & h3 {
    font-size: 1.5em;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.8);
    padding-top: 0px !important;
  }

  & h4 {
    font-size: 1em;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.6);
  }
}

.stepIndex {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  background-color: $mainColor;
  color: black;

  &[incoming-step="true"] {
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
  }
}
