.container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.icon {
  margin-right: 10px;
  color: lightgrey;
}

.key {
  display: inline-flex;
  color: grey;
  font-size: 0.8rem;
}

.value {
  display: inline-flex;
}
