.icon {
  font-weight: inherit;
  font-size: inherit;
}

.imageIcon {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 100%;
  }
}
