.container {
  background-size: cover;
  background-position: center;
  min-width: 200px;
  height: 300px;
  margin: 5px;
  border-radius: 5px;
  display: flex;
  align-items: flex-end;
  padding: 5px;
  color: white;
  font-weight: bold;
  text-shadow: 1px 1px 1px black;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  box-shadow: none;
  transition: box-shadow 330ms ease-in-out;

  &:hover {
    box-shadow: 0px 0px 100px 100px inset rgba(0, 0, 0, 0.6);

    .background {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}

.field {
  margin-bottom: 10px;
  padding: 0 !important;
  font-size: 13px !important;
  width: 100%;
  overflow: hidden;
}

.background {
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fafafa;
  font-size: 13px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  transition:
    transform 330ms ease-in-out,
    opacity 330ms ease-in-out;
  transform: translateY(50px);
}

.voteAverage {
  width: 80px;
  position: relative;

  .rate {
    max-width: 30px !important;
    li {
      margin: 0 !important;
      padding-left: 3px !important;
    }
    svg {
      width: 13px !important;
      height: 13px !important;
    }
  }

  .voteAverageCount {
    position: relative;
    top: -5px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

.overview {
  padding-left: 10px;
  display: -webkit-box;
  line-clamp: 5;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.title {
  font-size: 17px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 10px;
}

.genre {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
