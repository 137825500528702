@import "/src/styles/colors.scss";

.fullWidth {
  width: 100%;
}

.fullHeight {
  height: 100%;
}

.fitContent {
  height: fit-content;
  width: fit-content;
}

.heightInherit,
.inheritHeight {
  height: inherit;
}

.heightFitContent {
  height: fit-content;
}

.heightFillAvailable {
  height: stretch;
  height: available; // firefox compat
}

.widthInherit,
.inheritWidth {
  width: inherit;
}

.widthAuto {
  width: auto;
}

.widthFitContent {
  width: fit-content;
}

.widthFillAvailable {
  width: stretch;
  width: available; // firefox compat
}

.overflowYAuto {
  overflow-y: auto;
}

.overflowYHidden {
  overflow-y: hidden;
}

.overflowXAuto {
  overflow-x: auto;
}

.overflowXHidden {
  overflow-x: hidden;
}

.overflowHidden {
  overflow: hidden;
}

.noMargin {
  margin: 0;
}

.noPadding {
  padding: 0;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

.hidden {
  display: none;
}

.table {
  display: table;
}

.tableCell {
  display: table-cell;
}

.tableHeader {
  display: table-header-group;
}

.grid {
  display: grid;
}

.rowAuto {
  grid-auto-rows: min-content;
}

.col2 {
  grid-template-columns: repeat(2, fit-content(100%));
  grid-gap: 10px;
}

.col3 {
  grid-template-columns: repeat(3, fit-content(100%));
  grid-gap: 10px;
}

.col4 {
  grid-template-columns: repeat(4, fit-content(100%));
  grid-gap: 10px;
}

.col5 {
  grid-template-columns: repeat(5, fit-content(100%));
  grid-gap: 10px;
}

.col6 {
  grid-template-columns: repeat(6, fit-content(100%));
  grid-gap: 10px;
}

.col7 {
  grid-template-columns: repeat(7, fit-content(100%));
  grid-gap: 10px;
}

.col8 {
  grid-template-columns: repeat(8, fit-content(100%));
  grid-gap: 10px;
}

.col9 {
  grid-template-columns: repeat(9, fit-content(100%));
  grid-gap: 10px;
}

.noGridGap {
  grid-gap: 0;
}

.gridGap3 {
  grid-gap: 3px;
}

.gridGap5 {
  grid-gap: 5px;
}

.gridGap7 {
  grid-gap: 7px;
}

.gridGap9 {
  grid-gap: 9px;
}

.cell {
  display: table-cell;
}

.transition300 {
  transition: 300ms;
}

.block {
  display: block;
}

.inlineBlock {
  display: inline-block;
}

.flex1 {
  flex: 1;
}

.flex {
  display: flex;
}

.inlineFlex {
  display: inline-flex;
}

.row {
  flex-direction: row;
}

.rowReverse {
  flex-direction: row-reverse;
}

.column {
  flex-direction: column;
}

.columnReverse {
  flex-direction: column-reverse;
}

.start {
  justify-content: flex-start;
}

.end {
  justify-content: flex-end;
}

.center {
  justify-content: center;
}

.stretch {
  justify-content: stretch;
}

.spaceAround {
  justify-content: space-around;
}

.spaceBetween {
  justify-content: space-between;
}

.spaceEvenly {
  justify-content: space-evenly;
}

.alignBaseline {
  align-items: baseline;
}

.alignSafeCenter {
  align-items: safe center;
}

.alignCenter {
  align-items: center;
  align-content: center;
}

.alignStart {
  align-items: flex-start;
  align-content: flex-start;
}

.alignEnd {
  align-items: flex-end;
  align-content: flex-end;
}

.selfStart {
  align-self: flex-start;
}

.selfEnd {
  align-self: flex-end;
}

.selfCenter {
  align-self: center;
}

.selfStretch {
  align-self: stretch;
}

.noWrap {
  flex-wrap: nowrap;
}

.wrap {
  flex-wrap: wrap;
}

.wrapReverse {
  flex-wrap: wrap-reverse;
}

.verticalAlign {
  vertical-align: middle;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.capitalizeFirstLetter::first-letter {
  text-transform: uppercase;
}

.ellipsis {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.textWrap {
  white-space: pre-line;
}

.textNoWrap {
  white-space: nowrap;
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.textLeft {
  text-align: left;
}

.floatRight {
  float: right;
}

.floatLeft {
  float: left;
}

.cursor {
  cursor: default;
}

.pointer {
  cursor: pointer;
}

.noPointerEvents {
  pointer-events: none;
}

.pointerEvents {
  pointer-events: all;
}

.noUserSelect,
.userSelectNone {
  user-select: none;
}

.userSelect {
  user-select: all;
}

.padding0 {
  padding: 0;
}

.padding5 {
  padding: 5px;
}

.padding7 {
  padding: 7px;
}

.padding8 {
  padding: 8px;
}

.padding10 {
  padding: 10px;
}

.padding15 {
  padding: 15px;
}

.padding20 {
  padding: 20px;
}

.padding30 {
  padding: 30px;
}

.paddingRight5 {
  padding-right: 5px;
}

.paddingRight7 {
  padding-right: 7px;
}

.paddingRight8 {
  padding-right: 8px;
}

.paddingRight10 {
  padding-right: 10px;
}

.paddingRight15 {
  padding-right: 15px;
}

.paddingRight20 {
  padding-right: 20px;
}

.paddingLeft5 {
  padding-left: 5px;
}

.paddingLeft7 {
  padding-left: 7px;
}

.paddingLeft8 {
  padding-left: 8px;
}

.paddingLeft10 {
  padding-left: 10px;
}

.paddingLeft15 {
  padding-left: 15px;
}

.paddingLeft20 {
  padding-left: 20px;
}

.paddingLeft30 {
  padding-left: 30px;
}

.paddingBottom5 {
  padding-bottom: 5px;
}

.paddingBottom7 {
  padding-bottom: 7px;
}

.paddingBottom8 {
  padding-bottom: 8px;
}

.paddingBottom10 {
  padding-bottom: 10px;
}

.paddingBottom15 {
  padding-bottom: 15px;
}

.paddingBottom20 {
  padding-bottom: 20px;
}

.paddingBottom30 {
  padding-bottom: 30px;
}

.paddingTop5 {
  padding-top: 5px;
}

.paddingTop7 {
  padding-top: 7px;
}

.paddingTop8 {
  padding-top: 8px;
}

.paddingTop10 {
  padding-top: 10px;
}

.paddingTop15 {
  padding-top: 15px;
}

.paddingTop20 {
  padding-top: 20px;
}

.paddingTop30 {
  padding-top: 30px;
}

.margin5 {
  margin: 5px;
}

.margin7 {
  margin: 7px;
}

.margin8 {
  margin: 8px;
}

.margin10 {
  margin: 10px;
}

.margin15 {
  margin: 15px;
}

.margin20 {
  margin: 20px;
}

.margin30 {
  margin: 30px;
}

.margin40 {
  margin: 40px;
}

.margin50 {
  margin: 50px;
}

.marginRight5 {
  margin-right: 5px;
}

.marginRight7 {
  margin-right: 7px;
}

.marginRight8 {
  margin-right: 8px;
}

.marginRight10 {
  margin-right: 10px;
}

.marginRight15 {
  margin-right: 15px;
}

.marginRight20 {
  margin-right: 20px;
}

.marginRight30 {
  margin-right: 30px;
}

.marginRight40 {
  margin-right: 40px;
}

.marginRight50 {
  margin-right: 50px;
}

.marginRightAuto {
  margin-right: auto;
}

.marginLeft5 {
  margin-left: 5px;
}

.marginLeft7 {
  margin-left: 7px;
}

.marginLeft8 {
  margin-left: 8px;
}

.marginLeft10 {
  margin-left: 10px;
}

.marginLeft15 {
  margin-left: 15px;
}

.marginLeft20 {
  margin-left: 20px;
}

.marginLeft30 {
  margin-left: 30px;
}

.marginLeft40 {
  margin-left: 40px;
}

.marginLeft50 {
  margin-left: 50px;
}

.marginLeftAuto {
  margin-left: auto;
}

.marginBottom5 {
  margin-bottom: 5px;
}

.marginBottom7 {
  margin-bottom: 7px;
}

.marginBottom8 {
  margin-bottom: 8px;
}

.marginBottom10 {
  margin-bottom: 10px;
}

.marginBottom15 {
  margin-bottom: 15px;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.marginBottom30 {
  margin-bottom: 30px;
}

.marginBottom40 {
  margin-bottom: 40px;
}

.marginBottom50 {
  margin-bottom: 50px;
}

.marginTop5 {
  margin-top: 5px;
}

.marginTop7 {
  margin-top: 7px;
}

.marginTop8 {
  margin-top: 8px;
}

.marginTop10 {
  margin-top: 10px;
}

.marginTop15 {
  margin-top: 15px;
}

.marginTop20 {
  margin-top: 20px;
}

.marginTop30 {
  margin-top: 30px;
}

.marginTop40 {
  margin-top: 40px;
}

.marginTop50 {
  margin-top: 50px;
}

.normal {
  font-weight: normal;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.fontSize6 {
  font-size: 6px;
}

.fontSize7 {
  font-size: 7px;
}

.fontSize8 {
  font-size: 8px;
}

.fontSize9 {
  font-size: 9px;
}

.fontSize10 {
  font-size: 10px;
}

.fontSize11 {
  font-size: 11px;
}

.fontSize12 {
  font-size: 12px;
}

.fontSize13 {
  font-size: 13px;
}

.fontSize14 {
  font-size: 14px;
}

.fontSize15 {
  font-size: 15px;
}

.fontSize16 {
  font-size: 16px;
}

.fontSize17 {
  font-size: 17px;
}

.fontSize18 {
  font-size: 18px;
}

.fontSize19 {
  font-size: 19px;
}

.fontSize20 {
  font-size: 20px;
}

.fontSize21 {
  font-size: 21px;
}

.fontSize22 {
  font-size: 22px;
}

.fontSize23 {
  font-size: 23px;
}

.fontSize24 {
  font-size: 24px;
}

.fontSize25 {
  font-size: 25px;
}

.fontSize26 {
  font-size: 26px;
}

.fontSize27 {
  font-size: 27px;
}

.fontSize28 {
  font-size: 28px;
}

.fontSize29 {
  font-size: 29px;
}

.fontSize30 {
  font-size: 30px;
}

.fontSize31 {
  font-size: 31px;
}

.fontSize32 {
  font-size: 32px;
}

.fontSize33 {
  font-size: 33px;
}

.fontSize34 {
  font-size: 34px;
}

.fontSize35 {
  font-size: 35px;
}

.fontSize36 {
  font-size: 36px;
}

.fontSize37 {
  font-size: 37px;
}

.fontSize38 {
  font-size: 38px;
}

.fontSize39 {
  font-size: 39px;
}

.fontSize40 {
  font-size: 40px;
}

.mainColor {
  color: $mainColor;
}
.mainColor30 {
  color: $mainColor30;
}
.mainColor {
  color: $mainColor;
}

.mainColorMedium {
  color: $mainColorMedium;
}
.mainColorLight {
  color: $mainColorLight;
}
.fontBlack {
  color: $fontBlack;
}
.fontWhite {
  color: $fontWhite;
}
.fontBlueLight {
  color: $fontBlueLight;
}
.cyanLight {
  color: $cyanLight;
}
.white {
  color: $white;
}
.black {
  color: $black;
}
.blackLight {
  color: $blackLight;
}
.grey {
  color: $grey;
}
.dimgrey {
  color: $dimgrey;
}
.lightgrey {
  color: $lightgrey;
}
.softgrey {
  color: $softgrey;
}
.greyExtraLight {
  color: $greyExtraLight;
}
.greyLight2 {
  color: $greyLight2;
}
.greyLight {
  color: $greyLight;
}
.greyMedium {
  color: $greyMedium;
}
.greyDark {
  color: $greyDark;
}
.greyExtraDark {
  color: $greyExtraDark;
}
.greyblue {
  color: $greyblue;
}
.blue {
  color: $blue;
}
.blueExtraLight {
  color: $blueExtraLight;
}
.blueDark {
  color: $blueDark;
}
.textExtraDark {
  color: $textExtraDark;
}
.cyan {
  color: $cyan;
}
.redLight {
  color: $redLight;
}
.red {
  color: $red;
}
.redLight {
  color: $redLight;
}
.green {
  color: $green;
}
.greenDark {
  color: $greenDark;
}
.greenLight {
  color: $greenLight;
}
.yellow {
  color: $yellow;
}
.yellowDark {
  color: $yellowDark;
}
.orange {
  color: $orange;
}
.transparent {
  color: $transparent;
}
.black {
  color: $black;
}
.backgroundHover {
  color: $backgroundHover;
}
.greyBackground {
  color: $greyBackground;
}
.borderColor {
  color: $borderColor;
}
.blueBorderColor {
  color: $blueBorderColor;
}
.greyBorderColor {
  color: $greyBorderColor;
}
