.content {
  h1 {
    font-size: 1.5rem;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.9);
    margin: 20px 0 10px 0;
    &:first-child {
      margin-top: 0;
    }
  }
  h2 {
    font-size: 1.3rem;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.9);
    margin: 25px 0 8px 0;
    &:first-child {
      margin-top: 0;
    }
  }
  h3 {
    font-size: 1.1rem;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.9);
    margin: 15px 0 0px 0;
    &:first-child {
      margin-top: 0;
    }
  }

  color: rgba(255, 255, 255, 0.7);

  padding-bottom: 60px;
}
