@import "/src/styles/colors.scss";

:global {
  #importButton-context-menu {
    padding: 0px !important;
  }
}

.container {
  height: 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 14px;
  position: relative;

  > :not(span) {
    margin-left: 6px;
  }
}

.release {
  display: flex;
  justify-content: center;
  padding: 0px 10px;
  align-items: center;
  height: 25px;
  border-radius: 4px;
}

.importedStatus {
  display: flex;
  justify-content: center;
  padding: 0px 10px;
  align-items: center;
  height: 25px;
  color: #ffffff;
  border-radius: 4px;

  &[import-status="notImported"] {
    background-color: $mainColor;
  }
  &[import-status="notFound"] {
    background-color: $red;
  }
  &[import-status="watching"] {
    background-color: $yellowDark;
  }
  &[import-status="available"] {
    background-color: $green;
  }
  &[import-status="downloading"] {
    background-color: $blueDark;
  }
}

.importButton {
  height: 25px;

  &[is-released="false"] {
    background-color: $blueDark;
  }
}
