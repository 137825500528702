.container {
  display: flex;
  flex-direction: column;
  font-size: 17px;
}

.background {
  position: absolute;
  opacity: 0.2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  pointer-events: none;
}

.title {
  position: relative;
  z-index: 1;
  width: 100%;
}

.mediaInfos {
  z-index: 1;
  display: flex;
  flex-direction: row;
  height: 100%;
  position: sticky;
  top: 10px;
}

.image {
  max-width: 250px;
  border-radius: 6px;
}

.rating {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.infos {
  margin-left: 20px;
}

.field {
  margin-bottom: 20px;
}
