.twTable {
  .rs-table-cell {
    &[role="columnheader"] {
      font-size: 15px;
      font-weight: 600;

      .rs-table-cell-content {
        padding: 0 20px !important;
        display: flex;
        align-items: center;
      }

      &.rs-table-cell-first {
        border-top-left-radius: 10px !important;
        // border-bottom-left-radius: 10px !important;
      }

      &.rs-table-cell-last {
        border-top-right-radius: 10px !important;
        // border-bottom-right-radius: 10px !important;
      }
    }
  }

  .rs-table-cell-full-text:hover {
    box-shadow: none !important;
  }
}
