.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
}

.rating {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
  &:not(:first-child) {
    margin-left: 10px;
  }
}

.logo {
  img {
    &[src$=".svg"] {
      height: 22px;
      max-width: 60px;
    }

    &[src$=".png"] {
      max-height: 30px;
      max-width: 50px;
    }
  }
  & > svg {
    position: relative;
    top: 9px;
    height: 28px;
    max-width: 60px;
  }
}
