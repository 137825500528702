@mixin scrollbar(
  $color: var(--rs-gray-400),
  $background: var(--rs-bg-overlay),
  $width: 12px,
  $hide: true
) {
  *::-webkit-scrollbar {
    background-color: transparent;
    width: $width;
    height: 12px;
    z-index: 999999;
  }

  *::-webkit-scrollbar-track {
    background-color: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 12px;
    border: 0 solid $background;
  }

  *::-webkit-scrollbar-button {
    display: none;
  }

  *:hover::-webkit-scrollbar-thumb {
    background-color: $color;
    border: 2px solid $background;
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: $color;
    border: 2px solid $color;
  }
}
