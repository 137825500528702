@import "/src/styles/mixin.scss";

.rs-tag {
  position: relative;
  z-index: 10;
}

.rs-table-cell {
  max-width: 100%;
}
.rs-table-cell-content {
  display: flex;
  align-items: center;
}

.rs-table-cell-wrap {
  max-width: 100%;
  min-width: 100%;
}

.rs-modal-wrapper {
  padding: 20px !important;
  background-color: rgba(0, 0, 0, 0.3);

  .rs-modal,
  .rs-modal-content {
    border-radius: 4px !important;
  }

  .rs-modal-header-close {
    width: 25px !important;
    height: 25px !important;
    border-radius: 4px !important;
    background-color: rgba(255, 0, 0, 0.3) !important;
    &:hover {
      background-color: rgba(255, 0, 0, 0.6) !important ;
    }
  }

  .rs-modal-body {
    position: unset;
  }
}

.rs-apps-wrapper {
  height: 100%;
  padding: 10px !important;
  padding-top: 10px !important;
  position: fixed;
  overflow: auto;
  z-index: 1050;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .rs-apps-dialog {
    padding: 10px !important;
    background-color: #383737;
    height: 100%;
    border-radius: 10px;
  }

  .rs-apps-content {
    height: 100%;
  }

  $headerHeight: 36px;

  .rs-modal-header {
    padding: 0px !important;
    margin-bottom: 0px !important;
    height: $headerHeight;
    max-height: $headerHeight;
  }

  .rs-modal-body {
    margin-top: 0px;
    height: calc(100% - #{$headerHeight});
    overflow: hidden;
    padding: 0px !important;

    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .rs-modal-header-close {
    top: 10px;
    right: 10px;
  }
}
