.cell,
.cellString,
.cellStatus,
.cellDate {
  display: flex;
  flex-flow: row nowrap;
  padding: 0 20px !important;
  height: 100%;
  width: 100%;
}

.cellDate {
  flex-flow: column;
  justify-content: center;

  .date {
    font-size: 0.8rem;
    font-weight: 600;
  }

  .from {
    font-size: 0.6rem;
    font-weight: 400;
  }
}

.cellString {
  align-items: center;
  font-weight: 600;
}

.cellDuration {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-weight: 600;
  padding: 0 20px;
  width: 100%;
  height: 100%;
}

.cellNote {
  line-height: 50px;

  :first-child {
    font-size: 20px;
    font-family: "Gilroy";
  }

  :last-child {
    font-size: 20px;
    font-family: "Gilroy";
  }
}

.cellStatus {
  display: flex;
  flex-direction: row;
  align-items: center;

  .icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-left: 20px;
  }
}

.cellComment {
  white-space: pre-wrap;
}

.cellRules {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  white-space: pre-wrap;
  text-align: center;
}
