.container {
  height: 140px;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;

  &[media-format="compact"] {
    padding: 2px 5px;
    height: 80px;
    font-size: 13px;
    line-height: 14px;
  }
}

.image {
  max-width: 100px;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 4px;
    height: 100%;
  }

  &[media-format="compact"] {
    max-width: 50px;
    min-width: 50px;
  }
}

.infos {
  justify-content: space-between;
  padding-left: 30px;
  min-width: calc(100% - 100px);
  max-width: calc(100% - 100px);

  &[media-format="compact"] {
    padding: 0 10px;
    min-width: calc(100% - 50px);
    max-width: calc(100% - 50px);
  }
}

.title {
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 200px;
  white-space: nowrap;

  &[media-format="compact"] {
    padding-right: 50px;
    font-size: 16px;
  }

  .titleText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(100% - 180px);

    &[media-format="compact"] {
      max-width: calc(100% - 80px);
    }
  }

  .year {
    font-size: 18px;
    font-weight: normal;
    color: rgba(255, 255, 255, 0.8);
    padding-left: 10px;

    &[media-format="compact"] {
      font-size: 14px;
    }
  }
}

.rate {
  width: 60px;
  margin-left: 10px !important;
  svg {
    width: 13px !important;
    height: 13px !important;
  }
}

.summary {
  max-width: 100%;
  white-space: pre-wrap;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;

  &[media-format="compact"] {
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
  }
}

.requestedBy {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  padding-top: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &[media-format="compact"] {
    display: none;
  }
}
