.container {
  width: 160px;
  height: 20px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  text-align: center;

  &[size-format="compact"][has-error="true"] {
    width: 30px;
  }
}

.progressBar {
  border-radius: 10px;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.speed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
}
