.container {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: calc(100% - 60px);
  pointer-events: none;
  z-index: 0;
  background-color: #212121;
  padding: 20px;
}

.subContainer {
  pointer-events: all;
  width: 100%;
  height: 100%;
}
