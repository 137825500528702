.tooltip {
  background-color: rgba(0, 0, 0, 0.8) !important;
  border-radius: 5px !important;
  border: 1px solid rgba(0, 0, 0, 0.5) !important;
  padding: 8px !important;
  box-shadow: rgb(100 100 111 / 20%) 0 7px 19px 0 !important;
  white-space: pre-wrap;
}

.tooltipText {
  color: #ffffff;
}
