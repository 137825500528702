.container {
  padding: 0 !important;
}

.menuContainer {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  min-width: 140px;
}

.option {
  height: 30px;
  padding: 10px;
  cursor: pointer;
  color: #fff;
  display: flex;
  min-width: 100%;
  align-items: center;
  user-select: none;

  &:hover {
    box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, 0.1);
  }
}
