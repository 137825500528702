:global(#downloads-control-id) {
  display: none;
}

.container {
  border-radius: 20px !important;
  height: 35px !important;
  border: none !important;

  &:hover,
  &:focus-visible,
  &:focus,
  &:focus-within {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }

  .input {
    background-color: transparent !important;
    width: 150px !important;
    transition: width 0.33s;
    &:focus {
      width: 250px !important;
    }
  }

  .menu {
    cursor: pointer;
  }
}
