@import "./mixin";

@include scrollbar();

html {
  position: relative;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell,
    "Helvetica Neue", sans-serif;
}

body {
  height: 100%;
  max-height: 100vh;
  margin: 0;
}

* {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

body > #webpack-dev-server-client-overlay {
  display: none;
}

body > #root {
  height: 100%;
  display: flex;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@include scrollbar();
