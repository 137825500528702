@import "/src/styles/mixin.scss";

.container {
  @include scrollbar($color: #212121, $background: #212121, $width: 1px, $hide: true);

  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
}

.mediasContainer {
  overflow-x: auto;
  width: 100%;
  max-width: 100%;
  height: fit-content;
}

.medias {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  height: 320px;

  & > :not(:first-child) {
    margin-left: 10px;
  }
}

.mediaPlaceholder {
  min-width: 200px;
  min-height: 300px;
  margin: 5px;
  border-radius: 5px;
  padding: 5px;
}
